@tailwind base;
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
li {
  font-size: revert; /* Use 'revert' to reset to user-agent styles */
  font-weight: revert; /* Reset font weight */
  line-height: revert; /* Reset line height */
  font-family: revert; /* Reset font family */
  text-decoration: revert;
}
a{
  text-decoration: none
}
@tailwind components;
@tailwind utilities;
/* globals.css */
.container {
  max-width: 1280px;
}
body {
  background-color: #f4f8ff;
}



.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
 

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-button-next,
.swiper-button-prev{
  color: black !important;
  font-size: 20 !important;
}